import React, { useEffect, useState } from "react";
import globalData from "../data/global";
import axios from "axios";
const SocialMedia = () => {
  const [allInfo, setallInfo] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  function Marquee(element, speed) {
    const clone = element.innerHTML;
    const firstElement = element.children[0];
    let i = 0;
    element.insertAdjacentHTML("beforeend", clone);
    element.insertAdjacentHTML("beforeend", clone);
      function step() {
      firstElement.style.marginRight = `-${i}px`;
      if (i > firstElement.clientWidth) {
        i = 0;
      }
      i += speed;
      requestAnimationFrame(step);
    }
    requestAnimationFrame(step);
  }
  
  function initializeMarqueeAfterDataLoad() {
    const marqueeElements = document.querySelectorAll(".marquee");
    marqueeElements.forEach((marqueeElement) => {
      if (marqueeElement.children.length > 0) {
        Marquee(marqueeElement, 1.2);
      }
    });
  }
  

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;

      if (scrollPosition < 120) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  useEffect(() => {
    const timeout = setTimeout(() => {
      initializeMarqueeAfterDataLoad();
    }, 1500);
    return () => clearTimeout(timeout);
  }, []);

  async function fetchGetItems() {
    const headers = {
      Accept: "*/*",
      key: globalData.xapikey,
      "X-Ignore-In-Logs": "true",
    };
    try {
      const response = await axios.get(
        `${globalData.apiLink}view.php?itemListOf=welcomeMessage`,
        { headers: headers }
      );
      setallInfo(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }

  useEffect(() => {
    fetchGetItems();
  }, []);

  return (
    <div>
      <div className="marquee">
        <span> {allInfo} </span>
      </div>
      <div className="messageAbsolute" style={{ display: isVisible ? 'block' : 'none' }} >
        <div className="marquee">
          <span> {allInfo} </span>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
