import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MQ from "./MQ";
import HaliIliya from "./HaliIliya";
import FrankCafe from "./FrankCafe";
import BackgroundMusic from "./BackgroundMusic";
import globalData from "../data/global";
import axios from "axios";




const HomePage = () => {
  const [allInfo, setallInfo] = useState([]);

    

  

  async function fetchGetItems() {
    const headers = {
      Accept: "*/*",
      "key": globalData.xapikey,
      "X-Ignore-In-Logs": "true",
    };
    try {
      const response = await axios.get(
        `${globalData.apiLink}view.php?itemListOf=info`,
        {headers:headers}
      );
      setallInfo(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }

  useEffect(() => {
    fetchGetItems();
  }, []);

  return (
    <div>

      <div className="container">
        <FrankCafe />

        {allInfo.welcome}
        <br></br>
        <br></br>
        <Link to="/menu">
          <div className="openMenuButton">
            <button className="btn-64">
              <span>{allInfo.openMenu}</span>
            </button>
          </div>
        </Link>
        <br></br>
        <div className="backgroundMusicButtonHomePage">
          <BackgroundMusic />
        </div>
        {allInfo.advertisement1 && (
          <div className="alert alert-warning mt-5" role="alert">
            <h4 className="alert-heading">اعلان</h4>
            <p>{allInfo.advertisement1}</p>
            <hr />
            <p className="mb-0">مع التحيات الادارة</p>
          </div>
        )}
        <HaliIliya />
      </div>
      <MQ />
    </div>
  );
};

export default HomePage;
