import React from 'react';

import { Icon } from "@iconify/react";
export const backgroundMusicReducer = (state={counter:1,menuStartStopButton:<Icon icon="ph:play-duotone" width="1.6em" height="1.6em" />,homeStartStopButton:"تشغيل الموسيقي"},action)=>{
  if(action.type==="PLAYANDPAUSE"){
    var newMenuStartStopButton = state.menuStartStopButton
    // var newHomeStartStopButton = state.homeStartStopButton
    if(state.counter % 2 === 1){
      newMenuStartStopButton = <Icon icon="ph:stop-duotone" width="1.6em" height="1.6em" />;
    }else{
      newMenuStartStopButton = <Icon icon="ph:play-duotone" width="1.6em" height="1.6em" />;
    }
    return {counter:state.counter + 1,menuStartStopButton:newMenuStartStopButton}
  }
  return state;
}