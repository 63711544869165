import React from 'react'

const NoPageFound = () => {
  return (
    <div>
      Page not Found!
    </div>
  )
}

export default NoPageFound
