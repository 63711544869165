import React from 'react'
import { Carousel } from 'react-bootstrap'
import globalData from '../data/global'

Carousel.control = false
const carousel = ({images}) => {
  return (
  
    <div>
      <Carousel fade pause="hover" interval="1000" variant="">
        {
          
          images.map((images,index)=>{
            return (
              <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={globalData.uploadsLink+images}
                alt="First slide"
              />
            </Carousel.Item>
            )
          })
        }
    </Carousel>
    </div>
  )
}

export default carousel
