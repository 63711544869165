import React, { useEffect, useState } from "react";
import axios from "axios";

import globalData from "../data/global";
const Offers = () => {
  const [allData, setAllData] = useState([]);
  const [allInfo, setallInfo] = useState([]);



  let headers = {
    Accept: "*/*",
    "key": globalData.xapikey,
    "X-Ignore-In-Logs": "true",
  };


  async function offers1() {
    try {
      const response = await axios.get(
        `${globalData.apiLink}view.php?itemListOf=items`,
        {headers:headers}
      );
      
      const allData = response.data;
      setAllData(allData); 
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }

  async function offers2() {
    try {
      const response = await axios.get(
        `${globalData.apiLink}view.php?itemListOf=info`,
        {headers:headers}
      );
      
      const allData = response.data;
      setallInfo(allData); 
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }

  useEffect(() => {
    offers1();
    offers2();
  }, []);

  return (
    <div>
      {allData
        .filter((items) => items.disc !== "0")
        .map((items, index) =>
          index === 0 ? (
            <div className="categorySecOffers" key={index}>
              <div className="so">
                <img src="images/siteImages/redBanner.png" alt="redBanner" />
              </div>
              <div>
                <div className="ItemCat">{allInfo.todaysOfferTitle}</div>
                <div className="ItemCat">{allInfo.todaysOfferMessage}</div>
              </div>
            </div>
          ) : null
        )}
      {allData
        .filter((item) => item.disc !== "0")
        .map((itemsa, indexa) => {
          return (
            <div className={"catItemsOffers"} key={indexa}>
              <div className="itemsNamesAndPricesOffers">
                <div>
                  <span>#{indexa + 1}</span> {itemsa.item} {" / "}{" "}
                  <span className={"categoryText"}>{itemsa.cat}</span>
                </div>
                <div><span role="img" aria-labelledby="">🔥</span> {itemsa.disc}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Offers;
