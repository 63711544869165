import React from 'react'
import globalData from '../data/global'
import { Icon } from "@iconify/react";
const SocialMedia = () => {
  return (
    <div>
      <span><a href="https://www.instagram.com/frank.rest.cafe/" target="_blank" rel="noopener noreferrer"><Icon icon="il:instagram" width="1.6em" height="1.6em" /></a></span>
    </div>
  )
}

export default SocialMedia
